// @flow

import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import LazyLoad from 'react-lazyload';

import Col from 'atomize/dist/atoms/grid/Col';
import ProductCard from './ProductCard';
import React, {useEffect} from "react";
import Row from 'atomize/dist/atoms/grid/Row';
import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import {getProductsState} from '../../redux/selectors';

function Products(props: Object) {
  const productsState = useSelector(getProductsState);

  const productsComps = []
  for (const [id, product] of Object.entries(productsState)) {

    const { imgData } = product;
    //const imgData = image.localFile.childImageSharp.gatsbyImageData;

    //const {fallback} = imgData.images;
    // Below works, but probably unnecessary - the images in cart are pretty small
    // const imgUrls = fallback.srcSet ? fallback.srcSet.split(',') : [fallback.src];
    // const imgUrl = imgUrls[imgUrls.length - 1].split(' ')[0];
    //const imgUrl = fallback.src;

    productsComps.push(
      <Col key={`Products:Col:${id}`} size={{xs: '12', md: '6', lg: '4'}} d='flex' flexDir='column' p={{l: '1rem', r: '1rem', t: '3rem', b: '3rem'}}>
        <LazyLoad offset={1000}>
          <ProductCard productId={id}>
            <Div p={{l: '4rem', r: '4rem', t: '2rem', b: '1rem'}}>

                <Row>
                  <Col size={{xs: '1', sm: '2', md: '3', lg: '1', xl: '2'}}></Col>
                  <Col size={{xs: '10', sm: '8', md: '6', lg: '10', xl: '8'}}>
                  <GatsbyImage image={imgData} alt={`${id} paveikslėlis`} style={{maxHeight: '300px'}} />
                  </Col>
                </Row>

            </Div>
          </ProductCard>
        </LazyLoad>
      </Col>
    );
  }

  return productsComps;
}

export default function ProductsView(props: Object) {

  return (
    <Div id={props.id}>
      <Text tag='h2' textAlign='center' textSize='heading' p={{t: '4rem'}} >
        Natūralūs Maisto Papildai
      </Text>

        <Row p={{t: '2rem', b: '2rem'}}>
          <Products />
        </Row>

    </Div>
  );
}
