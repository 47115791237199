// @flow

import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';
import Button from 'atomize/dist/atoms/button/Button';
import { BsArrowDown as DownIcon } from 'react-icons/bs';
import scrollTo from 'atomize/dist/functions/scrollTo';

//import {scrollTo} from 'atomize';

import HtmlHead from '../components/HtmlHead';
import ProductsView from '../components/shop/ProductsView';

export default function Home({location}: Object) {

  return (
    <>
      <HtmlHead title='Sveikatos patarimai - natūralūs maisto papildai | bukitesveiki.lt' description="Viskas, ko reikia sveikam žmogui - nuo sveikatos patarimų iki natūralių maisto papildų." meta={{keywords: 'natūralūs maisto papildai,Vision maisto papildai,sveikata,sveikas žmogus,bukitesveiki.lt'}} />
        <Div className='landing' p={{t: {xs: '4rem', md: '4rem'}, b: {xs: '4rem', md: '6rem'}}} >
          <Container>
            <Div textAlign='center'>
              <Row>
                <Col size={{xs: '0', md: '5'}} order={{xs: '3', md: '1'}}>
                  <StaticImage layout='fluid' src='../images/landing4.jpg' maxWidth={600} maxHeight={800} alt='bukitesveiki.lt tlc produktai' />
                </Col>
                <Col size={{xs: '0', md: '1'}} order={{xs: '2', md: '2'}}></Col>
                <Col size={{xs: '12', md: '6'}} order={{xs: '1', md: '3'}} d='flex' justify='center' align='center' flexDir='column'>
                  <Text textColor='black' textSize='display1' tag='h1' p={{b: '1rem'}}>
                    Norite gyventi sveikiau?
                  </Text>
                  <Text textColor='black' textSize='body' p={{b: '2rem'}}>
                    Čia rasite visko, ko reikia sveikam žmogui - nuo sveikatos patarimų iki <Text tag='span' textWeight='600'>natūralių</Text> Vision maisto papildų.
                  </Text>
                  <Row >
                    <Col >
                      <Button w='100%'
                        shadow="3"
                        hoverShadow="4"
                        h='3rem'
                        m={{ r: "3rem" }}
                        rounded='circle'
                        onClick={() => scrollTo('#productsView')}
                      >
                        Parduotuvė <DownIcon className='ui-icon' size='1.5rem'/>
                      </Button>
                      <Div p={{b: '1rem'}}></Div>
                    </Col>

                    {
                      // <Col >
                      //   <Button w='100%' bg='white' textColor='black' shadow="3" hoverShadow="4" h='3rem' m={{ r: "3rem" }} rounded='circle' textWeight='400'>
                      //     Konsultuotis
                      //   </Button>
                      // </Col>
                    }
                  </Row>
                </Col>
              </Row>
            </Div>

                {
                  //                 <StaticImage layout='fluid' src='../images/tlc7.png' maxWidth={500} maxHeight={350} alt='bukitesveiki.lt tlc produktai' />
                }


          </Container>
        </Div>
      <Div bg='gray300'>
        <Container >
          <ProductsView id='productsView' />
        </Container>
      </Div>

    </>
  );
}
