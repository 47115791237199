// @flow

import React from 'react';
import {useSelector} from 'react-redux';

import AddToCartBtn from './AddToCartBtn';
import Div from 'atomize/dist/atoms/div/Div';
import Row from 'atomize/dist/atoms/grid/Row';
import Link from '../Link';
import Text from 'atomize/dist/atoms/text/Text';
import MenuLink from '../MenuLink';
import Button from 'atomize/dist/atoms/button/Button';

import { RiShoppingBasketLine as CartIcon } from 'react-icons/ri';
import { BsArrowRight as RightIcon } from 'react-icons/bs';

import {getProduct, getProductPriceLbl} from '../../redux/selectors/productsSelectors';

type Props = {
  +productId: string,
  +children?: any
}

// responsive equal height https://codepen.io/bootstrapped/pen/RrabNe
// unfortunately the component styling is coupled with the parent

export default function ProductCard(props: Props) {

  const { productId, children} = props;

  const priceLbl = useSelector(state => getProductPriceLbl(state, productId));
  const product = useSelector(state => getProduct(state, productId));
  const { title, excerpt, imgData } = product;

  const toUrl = `/produktai/${productId}`;

  return (
    <Div hoverShadow='4' rounded='lg' d='flex' flexDir='column' style={{flex: '1 0 auto'}}>
      <Div d='flex' flexDir='column' style={{flex: '1 0 auto'}}>
        <Div flexGrow={1}>
          <MenuLink to={toUrl}>
            {
              children
            }

            <Div rounded='lg' p={{xs: '1rem'}} >
              <Text textWeight='600' textSize='subheader'>
                {title}
              </Text>

              <Text p={{t: '1rem'}} textSize='caption' textWeight='400'>
                {excerpt}
              </Text>

              {
                // <Button p='0rem' m={{t: '1rem'}} textWeight='400' textColor='black' bg='gray300'>Skaityti daugiau <RightIcon size='1.5rem' /></Button>
              }
            </Div>
          </MenuLink>
        </Div>

        <Div rounded='lg' p={{t: '0rem', b: '1rem', r: '1rem', l: '1rem'}}>

          <Text textSize='subheader' textWeight='600' p={{b: '1rem'}}>
            {priceLbl}
          </Text>

          <AddToCartBtn textSize='caption' productId={productId}>Į Krepšelį <CartIcon size='1rem' className='ui-icon'/></AddToCartBtn>
        </Div>
      </Div>
    </Div>
  );
}
